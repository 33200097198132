.selector {
  margin-top: 32px;
  margin-bottom: 32px;
  display: flex;
  border-bottom: 2px solid var(--Grey-20);
  justify-content: space-between;
}

.jobOfferList {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.tag {
  height: 20px;
  box-sizing: border-box;
  display: flex;
  margin-left: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background-color: #feefeb;
  color: var(--error-red);
  padding: 0 4px;
}

.short {
  height: 28px;
  box-sizing: border-box;
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--Grey-20, #e1e0df);
  border-radius: 4px;
}

.jobBox {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid var(--grey-20);
  border-radius: 8px;
  padding: 24px;
  z-index: 5;
}

.infoCard {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  flex-shrink: 0;
  border-radius: 8px;
  background: #f1f7fb;
  padding: 24px;
  margin-bottom: 24px;

  i {
    color: #195f8e;
  }
}

.closeBtn {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.message {
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.notification {
  display: flex;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  text-align: center; /* Текст по центру */
  color: var(--White);
  background-color: var(--ErrorRed);
  box-sizing: border-box;
  p {
    color: white !important;
  }
}

.selectorItem {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
  gap: 8px;
  align-items: center;
  text-align: center;
  padding-bottom: 8px;
  position: relative;
  cursor: pointer;
  color: var(--dark-grey-text);
}

.selectorItem p {
  margin: 0;
}

.selectorItem.active {
  color: var(--petrol-100);
}

.selectorItem.active p {
  color: var(--petrol-100);
}

.selectorItem.active::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--petrol-100);
}

.jobOfferPage {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.jobOfferWrapper {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 100%;
  margin-top: 32px;
  z-index: 5;
}

.jobOfferInfo {
  background-color: white;
  border: 1px solid var(--Grey-20, #e1e0df);
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.fullBorder {
  border-radius: 8px;
}

.leftBorder {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  flex: 60%;
}

.rightBorder {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.chatWrapper {
  padding: 32px;
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 650px;
  border: 1px solid var(--Grey-20, #e1e0df);
  border-left: none;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  flex: 40%;
}

.logo {
  width: 48px;
  height: 48px;
  border-radius: 8px;
}

.itemList {
  display: flex;
  padding: 8px 16px;
  border-radius: 24px;
  border: 1px solid var(--Grey-20, #e1e0df);
}

.video_list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.video_item {
  aspect-ratio: 16 / 9;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;

  iframe {
    width: 100%;
    aspect-ratio: 16 / 9;
  }
}

.photoList {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: flex-start;
}

.photoContainer {
  width: calc(25% - 16px);
  aspect-ratio: 1 / 1;
  overflow: hidden;
  position: relative;
}

.photoPlace {
  aspect-ratio: 1 / 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--beige-100);
  border-radius: 8px;
}

.photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.contractField {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 16px;
  justify-content: space-between;
  border-radius: 8px;
  background-color: #ebf7e7;
}
