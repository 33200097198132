.chat {
  /* height: 100%; */
  height: 600px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
}

.messageList {
  margin: 16px 0;
  display: flex;
  flex-direction: column-reverse;
  flex: 1;
  gap: 16px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 5px;
}

.messageWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.messageContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 16px;
  align-items: flex-end;
}

.message {
  display: flex;
  flex: 1;
  align-items: flex-start;
  text-align: start;
  padding: 8px;
  border-radius: 8px;
  gap: 16px;
  flex-direction: column;
  justify-content: center;
}

.outMessage {
  background-color: var(--Beige-80);
  border-bottom-right-radius: 0;
}

.inMessage {
  background-color: #f7f7f7;
  border-bottom-left-radius: 0;
}

.chatlogo {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: var(--Yellow-100);
  width: 24px;
  height: 24px;
}

.logo {
  border-radius: 12px;
  width: 24px;
  height: 24px;
}

.inputWrapper {
  border-radius: 8px;
  border: 1px solid var(--Grey-20, #e1e0df);
  background: var(--White, #fff);
  position: relative;
}

.chatInstrument {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  color: var(--grey-60);
  i {
    cursor: pointer;
  }
}

.chatInput {
  width: 100%;
  height: 50px;
  resize: none;
  border: none;
}

.uploadWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: absolute;
  top: -40px;
  left: 50%;
  width: 100%;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ccc;
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
  border-radius: 8px;
  border: 1px solid var(--Grey-20, #e1e0df);
  background: #fff;
  padding: 20px;
  z-index: 20;
  border-radius: 8px;
}

.newInterview {
  cursor: pointer;
  display: flex;
  padding: 8px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--Petrol-100, #155263);
  background: var(--White, #fff);
}

.timeZoneInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: #f1f7fb;
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 8px;
}
