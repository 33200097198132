/* Dropdown.module.css */
.dropdown {
  position: relative;
  display: block;
  width: 100%;
}

.dropdownButton {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  text-align: left;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.dropdownText {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 8px;
}

.arrow {
  flex-shrink: 0;
}

.dropdownContent {
  position: absolute;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  z-index: 104;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  display: none;
}

.dropdownContent.show {
  display: block;
}

.dropdownItem {
  padding: 8px;
  cursor: pointer;

  white-space: normal;
  word-break: break-word;
  overflow-wrap: break-word;
}

.dropdownItem:hover {
  background-color: #f0f0f0;
}

.error {
  border: solid 1px var(--errorRed);
}

.selectedItem {
  background-color: var(--beige-80);
}

@media screen and (max-width: 900px) {
  .dropdownItem {
    white-space: normal;
    word-break: break-word;
    overflow-wrap: break-word;
  }
}
