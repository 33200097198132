@import url("./buttons.css");
@import url("./fonts.css");
@import url("./custom.css");
@import url("./test.css");

html {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
}

body {
  overflow: visible;
  margin: 0;
  padding: 0;
}

form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
textarea {
  border: 1px solid var(--Grey-40, #c3c1c0);
  background: var(--White, #fff);
  width: 100%;
  font-size: 16px;
  outline: none;
  border-radius: 8px;
  height: 48px;
  box-sizing: border-box;
  padding: 6px 6px 6px 25px;
}

input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="password"]::placeholder,
input[type="number"]::placeholder,
textarea::placeholder {
  text-decoration: none;
  font-size: 16px;
}

input[type="checkbox"] {
  min-height: 18px;
  min-width: 18px;
  margin-right: 10px;
}

textarea {
  height: 150px;
  resize: vertical;
}

.custom-radio {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: white;
  border: 2px solid var(--grey-100);
  border-radius: 50%;
  vertical-align: text-top;
  margin-right: 5px;
  z-index: 10;
}

.custom-radio::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: var(--orange-100);
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: none;
}

.real-radio {
  z-index: 20;
  margin-right: 5px;
  width: 16px;
  height: 16px;
  opacity: 0;
  position: absolute;
}

.real-radio:checked + .custom-radio {
  border: 2px solid var(--orange-100);
}

.real-radio:checked + .custom-radio::before {
  display: inline-block;
}

hr {
  all: unset;
}

ul {
  padding-inline-start: 0;
  list-style: none;
  margin-block: 0;
  border-radius: 8px;
}

.modal_img {
  height: 90px;
}

input[type="text"]:disabled {
  background-color: rgba(195, 192, 187, 0.7);
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 200, "opsz" 24;
}
