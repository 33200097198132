.container {
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.highlight {
  color: #007bff;
}

.contentBlock {
  margin-top: 20px;
}

.status.open {
  color: green;
}

.status.closed {
  color: red;
}

.status.pending {
  color: orange;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 15px;
}

th,
td {
  padding: 10px;
  border: none; /* Убираем границы */
  text-align: left;
}

tbody tr {
  height: 50px;
  background-color: white;
  border-radius: 8px;
}

tbody tr td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

tbody tr td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
