@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&display=swap");
@import url("https://api.fontshare.com/v2/css?f[]=general-sans@400,500,600&display=swap");

body {
  font-family: "General Sans", sans-serif !important;
}

textarea {
  font-family: "General Sans", sans-serif;
}

/* Setting font for headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  all: unset;
  font-family: "Bricolage Grotesque", sans-serif;
  font-weight: 700;
  z-index: 10;
  color: var(--dark-grey-text);
  /* padding: 10px 0; */
  white-space: normal;
  word-break: normal;
}

.white {
  color: white;
}

/* Setting font sizes for each heading */
h1 {
  font-size: 64px; /* Size for h1 */
}

h2 {
  font-size: 48px; /* Size for h2 */
}

h3 {
  font-size: 32px; /* Size for h3 */
}

h4 {
  font-size: 24px; /* Size for h4 */
}

h5 {
  font-size: 20px; /* Size for h4 */
}

.highlight {
  position: relative;
  display: inline-block;
  font-weight: bold;
  z-index: 1;
}

.highlight_full {
  margin: 0px;
  display: inline-flex;
  position: relative;
}

.highlight_full::before {
  content: "";
  position: absolute;
  top: 50%;
  width: 100%;
  height: 80%;
  background-color: var(--orange-20);
  transform: translateY(-50%) skewX(20deg);
  z-index: -1;
  /* clip-path: polygon(0 10%, 56% 6%, 99% 15%, 97% 100%, 0% 100%); */
  clip-path: polygon(1% 8%, 54% 14%, 97% 25%, 100% 97%, 55% 91%, 4% 92%);
  border-radius: 2px; /* Радиус границы для скругленных углов */
}

.highlight::before {
  content: "";
  position: absolute;
  top: 65%;
  left: -10px;
  width: 120%;
  height: 50%;
  background-color: var(--orange-40);
  transform: translateY(-50%) skewX(20deg);
  z-index: -1;
  clip-path: polygon(0 0, 56% 6%, 95% 15%, 100% 100%, 0% 95%);
}

p {
  color: var(--dark-grey-text);
  all: unset;
  /* padding: 5px 0; */
  white-space: normal;
  word-break: normal;
  /* overflow-wrap: normal; */
}

p.error {
  color: var(--errorRed);
}

/* Text M - semibold */
.text-m-semibold {
  font-weight: 600; /* Semibold */
  font-size: 16px; /* Size for text M */
}

/* Text M - regular */
.text-m-regular {
  font-weight: 400; /* Regular */
  font-size: 16px; /* Size for text M */
}

/* Text S */
.text-s-semibold {
  font-weight: 600;
  font-size: 14px;
}

.text-s-medium {
  font-weight: 500;
  font-size: 14px;
}

.text-s-regular {
  font-weight: 400;
  font-size: 14px;
}

/* Text XS */
.text-xs-semibold {
  font-weight: 800; /* Semibold */
  font-size: 12px; /* Size for text XS */
}

.text-xs-medium {
  font-weight: 500;
  font-size: 12px;
}

.text-xs-regular {
  font-weight: 400;
  font-size: 12px;
}

.text-xxs {
  font-size: 10px;
}

.text-20 {
  font-size: 20px;
}

h1.center,
h2.center,
h3.center,
h4.center,
h5.center,
h6.center,
p.center {
  text-align: center;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.link {
  cursor: pointer;
}

.left {
  text-align: left;
}

.orange {
  color: var(--orange-100);
}

p.end {
  text-align: end;
}
