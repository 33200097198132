.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.container {
  display: flex;
  width: 1080px;
}
