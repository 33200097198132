.stepContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  position: relative;
}

.step {
  color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  position: relative;
}

.activeStep {
  /* color: #ff7043; */
}

.circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #ffe6cc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;

  margin-bottom: 8px;
  z-index: 1;
}

.circle.active {
  background-color: #ff7043;
  color: white;
}

.label {
  font-size: 14px;
}

.inputsWrapper {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid #e1e0df;
  background: var(--White, #fff);
  padding: 48px;
  gap: 48px;
}

@media (max-width: 768px) {
  .stepContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .step {
    flex-direction: row;
    align-items: center;
  }

  .label {
    margin-left: 10px;
  }
}
