.values_list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.value_item {
  padding: 10px 20px;
  border: 1px solid var(--orange-100);
  color: var(--orange-100);
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.1s, color 0.1s;
}

.value_item.selected {
  background-color: var(--orange-100);
  color: white;
}

.value_item.disabled {
  border-color: var(--grey-60);
  background-color: var(--grey-20);
  color: grey;
  cursor: default;
}

.videoItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin-bottom: 10px;
}

.videoRow {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}

.videoItem .MuiTextField-root {
  flex-grow: 1;
}

.videoItem .MuiIconButton-root {
  margin-left: 10px;
}

.benefit_icon {
  margin-right: 10px;
  width: 30px;
}

.benefit_item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.benefit_item button {
  background-color: white;
  border-radius: 8px;
  color: var(--orange-100);
  border: 1px solid var(--orange-100);
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.1s, color 0.1s;
}

.benefit_item button.added {
  background-color: var(--orange-20);
  color: var(--orange-100);
}

.benefit_item button:hover {
  background-color: var(--orange-60);
  border: 1px solid var(--orange-60);
  color: white;
}

.video_button {
  padding: 0 20px;
  height: 50px;
  color: var(--orange-100);
  background-color: var(--orange-20);
}
